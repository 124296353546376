import React, { Suspense, lazy, useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import PageHeader from "@containers/page-header/layout-03";
import ClientFacts from "@containers/language-translation/client-facts/layout-02";
import Results from "@containers/language-translation/client-facts";
import OurSolution from "@containers/language-translation/our-solution";
import UseScroll from "@containers/scroll";
const Footer = lazy(() => import("@layout/footer/layout-01"))
const Agoda = ({ location, data }) => {
  const [showRestComponents, setShowRestComponents] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);
  UseScroll((scrollTop) => {
    if (scrollTop > 20) {
      setShowRestComponents(true);
    } else {
      setShowRestComponents(false);
    }
  }, setIsMobile);
  return (
    <Layout location={location}>
      <Seo
        title="Case study - Agoda localization"
        description="Part of the Priceline Group, Agoda is an industry leader dedicated to helping travelers from all over the globe get the best prices on more than 2 million hotels, resorts and homes."
      />
      <Header
        data={{
          ...globalContent["header"],
          ...globalContent["menu"],
          isMobile: isMobile,
        }}
      />
      <main className="site-wrapper-reveal">
        <PageHeader layout={5} data={content["agoda-header-section"]} />
        <ClientFacts layout={4} data={content["agoda-client-challenges"]} />
        {
          (!isMobile || (isMobile && showRestComponents)) && <>
            <OurSolution data={content["agoda-solutions"]} />
          </>
        }
        {
          showRestComponents && <Results data={content["agoda-results"]} />
        }
      </main>
      {
        showRestComponents && <Suspense fallback={<div></div>}>
          <Footer data={{ ...data.site.siteMetadata }} />
        </Suspense>
      }
    </Layout>
  );
};

export const query = graphql`
  query caseAgodaPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "use-cases" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
  }
`;

Agoda.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default Agoda;
